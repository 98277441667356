import React, { useEffect, useState } from 'react';
import Address from '../components/address/address';
import { getBannerInfo, getUrlParams, changeNumPrice, alertShow, createOderPay, getOrderState } from '../assets/scripts/api';
import Sku from './components/SKU/sku';
import Banner from './components/Banner/banner'
import Code from './components/Code/code'
import { newSetClickCount } from '../assets/scripts/common'
import { registerFunction, unRegisterFunction, backPage } from '../assets/scripts/native'
import './pay.css';


let timer = null;
let count = 50;
function Pay(props) {
  const [productId, setProductId] = useState(0)
  const [data, setData] = useState();
  const [skuList, setSkuList] = useState([]);
  const [skuData, setSkuData] = useState({})
  const [showAddress, setShowAddress] = useState(false);
  const [bannerList, setBannerList] = useState([]);
  const [skuActiveId, setSkuActiveId] = useState(0);
  const [addressInfo, setAddress] = useState('')
  const [num, setNum] = useState(1)
  const [yfPrice, setYfPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [payType, setPayType] = useState(5) // 5微信 4支付宝
  const [codeImg, setCodeImg] = useState('');
  const [codeFlag, setCodeFlag] = useState(false);
  const [isBig, setIsBig] = useState(true);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getOrderStatesResult = (id) => {
    if (timer) {
      count = 50;
      clearInterval(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timer = setInterval(() => {
      if (+count <= 0) {
        clearInterval(timer);
        count = 50;
        return
      }
      getOrderState(id).then(res => {
        if (+res === 1) {
          clearInterval(timer);
          newSetClickCount('diy_pc', 'phonecase_payment_success');
          window.location.href = '/result?name=' + getUrlParams('name', props.location.search) + '&smallui=' + getUrlParams('smallui', props.location.search) + '&oderid=' + id + '&img=' + getUrlParams('print_url', props.location.search) + '&banner=' +  getUrlParams('banner', props.location.search) ;
          // eslint-disable-next-line react-hooks/exhaustive-deps
          count = 50;
        }
      });
      count--;
      // getOrderStatesResult(id)
    }, 2000);
  }



  const backPageFun = () => {
    const flag = sessionStorage.getItem('ischange');
    if (+flag === 1) {
      backPage()
    } else {
      window.location.href = `http://pc.uyoungdiy.com${props.location.search}`;
    }

  }

  const showAddressFun = (flag) => {
    if (flag) {
      newSetClickCount('diy_pc', 'phonecase_texture_address');
    }

    setShowAddress(flag);
    if (localStorage.getItem('addressInfo')) {
      const info = JSON.parse(localStorage.getItem('addressInfo'));
      setAddress(info)
    }
  }


  const changeAddressInfo = (info) => {
  }

  const changeSku = (item) => {
    setSkuActiveId(item.attr_id);
    setBannerList(data['attribute_img_list'][item.attr_id])
    setSkuData(data['sku_list'][item.attr_id]);
    setYfPrice(data['sku_list'][item.attr_id]['yf_price'])
    setTotalPrice(data['sku_list'][item.attr_id]['real_price'])
    setNum(1)
    changeNum('', data['sku_list'][item.attr_id]['sku_id'])
  }

  const changeNum = (type, id) => {
    let req = {
      order_from: 'pc',
      page_from: 'pc',
      web_data: []
    }

    let item = {
      diyname: '定制手机壳',
      product_id: getUrlParams('productId', props.location.search),
      product_name: getUrlParams('name', props.location.search),
      cat_0: getUrlParams('cat_0', props.location.search),
      amount: num,
      ori_url: [{ url: getUrlParams('ori_url', props.location.search) }],
      print_url: [{ url: getUrlParams('print_url', props.location.search) }],
      show_url: [{ url: getUrlParams('show_url', props.location.search) }],
      sku_id: id || skuData.sku_id
    }

    if (type === 'add') {
      item.amount = item.amount + 1 > 200 ? 200 : item.amount + 1;
    } else if (type === 'reduce') {
      item.amount = (item.amount - 1) > 1 ? item.amount - 1 : 1;
    } else {
      item.amount = 1;
    }
    setNum(item.amount)
    req.web_data.push(item);
    req.web_data = JSON.stringify(req.web_data);
    changeNumPrice(req).then(res => {
      if (res) {
        setYfPrice((res.total_yf_price))
        setTotalPrice((res.pay_price))
      }
    })
  }

  const changePayType = (type) => {
    setPayType(type);
  }


  const payFun = () => {

    if (!addressInfo.name) {
      alertShow('请填写收货地址', 3000)
      return
    }

    if (!addressInfo.province) {
      alertShow('请填写收货地址', 3000)
      return
    }

    if (!addressInfo.city) {
      alertShow('请填写收货地址', 3000)
      return
    }

    if (!addressInfo.area) {
      alertShow('请填写收货地址', 3000)
      return
    }


    if (!addressInfo.street) {
      alertShow('请填写收货地址', 3000)
      return
    }





    let req = {
      order_from: 'pc',
      pay_type: payType,
      total_price: totalPrice,
      page_from: 'qiekenao',
      web_data: [],
      user_address: {
        name: addressInfo.name,
        province: addressInfo.province,
        city: addressInfo.city,
        area: addressInfo.area,
        phone: addressInfo.phone,
        street: addressInfo.street
      }
    }

    let item = {
      diyname: '定制手机壳',
      product_id: productId,
      product_name: getUrlParams('name', props.location.search),
      cat_0: getUrlParams('cat_0', props.location.search),
      amount: num,
      ori_url: [{ url: getUrlParams('ori_url', props.location.search) }],
      print_url: [{ url: getUrlParams('print_url', props.location.search) }],
      show_url: [{ url: getUrlParams('show_url', props.location.search) }],
      sku_id: skuData.sku_id
    }

    req.web_data.push(item)
    req.web_data = JSON.stringify(req.web_data);
    req.user_address = JSON.stringify(req.user_address);
    newSetClickCount('diy_pc', 'phonecase_texture_buy');
    createOderPay(req).then(res => {
      if (res) {
        setCodeImg(res.code_url);
        setCodeFlag(true)
        getOrderStatesResult(res.order_id)
      }

    })


  };

  const closeCode = () => {
    setCodeFlag(false)
  }

  const goBack = () => {

    props.history.go(-1);
  }

  useEffect(() => {
    if (window && window.external && 'Execute' in window.external) {

      unRegisterFunction();
      registerFunction('on_diy', (res) => {
        const data = JSON.parse(res);
        if (data.wp_type === 'static_wp') {
          window.location.href = `/sku?url=${data.url}&smallui=${getUrlParams('smallui', props.location.search)}`;
        } else {
          window.location.href = `/sku?url=none&smallui=${getUrlParams('smallui', props.location.search)}&path=${data.url}`;
        }
      })
    }
    const productID = getUrlParams('productId', props.location.search);
    setProductId(productID);
    const small = getUrlParams('smallui', props.location.search);
    setIsBig(+small === 1 ? true : false);
    getBannerInfo(`?product_id=${productID}&cat_0=1&order_from=pc&page_from=pc`).then(res => {
      setData(res);
      const _id = res.attribute_cat_list[0]['attribute_list'][0]['attr_id']
      setSkuList(res.attribute_cat_list[0]['attribute_list']);
      setSkuActiveId(_id);
      setBannerList(res['attribute_img_list'][_id]);
      setSkuData(res['sku_list'][_id]);
      setYfPrice(res['sku_list'][_id]['yf_price'])
      setTotalPrice(res['sku_list'][_id]['real_price']);
      changeNum('', res['sku_list'][_id]['sku_id'])
    });
    if (localStorage.getItem('addressInfo')) {
      const info = JSON.parse(localStorage.getItem('addressInfo'));
      setAddress(info)
    }
  }, [props.location.search])

  return (
    <div className={isBig ? 'content payBox small' : 'content payBox'}>
      {showAddress && <Address click={showAddressFun} save={changeAddressInfo} />}
      <div className="topBox">
        <div className="leftBox">
          <div className="retrunBox"><a href="#!" onClick={() => { goBack() }}><span className="icon"></span>  <div className="textBox">材质选择</div></a></div>

        </div>
        <div className="rightBox">
          <a href="#!" onClick={() => { backPageFun() }}><span></span></a>
        </div>
      </div>

      <div className="showPay">
        <div className="leftBox">
          {bannerList && Array.isArray(bannerList) && <Banner list={bannerList} skuActiveId={ skuActiveId}/>}
          <div className="showPaySku">
            <div className="titleBox">
              <p>选择手机壳制作工艺</p>
            </div>
            {skuList && Array.isArray(skuList) && <Sku list={skuList} click={changeSku} id={skuActiveId} />}

          </div>
        </div>
        <div className="rightBox">
          <div className="addressBox" onClick={() => { showAddressFun(true) }}>
            {!addressInfo && <span>添加收货地址</span>}
            {addressInfo && <div className="addressInfoShow"><div className="nickBox">{addressInfo.name}<span>{addressInfo.phone}</span></div><div className="addressInfo">{addressInfo.province + addressInfo.city + addressInfo.area + addressInfo.street}</div></div>}
          </div>
          <div className="infoBox">
            <div className="group">
              <div className="titleBox">价格：</div>
              <div className="lineBox"></div>
              <div className="info"><span className="showPrice">{'¥' + skuData.real_price / 100}</span> {+ skuData.show_price > 0 && <del className="realPrice">{'¥' + skuData.show_price / 100}</del>}</div>
            </div>
            <div className="group">
              <div className="titleBox">数量</div>
              <div className="lineBox"></div>
              <div className="info"><button type="button" onClick={() => { changeNum('reduce') }}>-</button><input type="text" value={num || 1} className="numInput" readOnly /><button type="button" onClick={() => { changeNum('add') }}>+</button></div>
            </div>
            <div className="group">
              <div className="titleBox">运费</div>
              <div className="lineBox"></div>
              {+yfPrice <= 0 && <div className="info">免费</div>}
              {+yfPrice > 0 && <div className="info">¥{yfPrice / 100}</div>}
            </div>
            <div className="group">
              <div className="titleBox">购买金额合计：</div>
              <div className="lineBox"></div>
              <div className="info">￥{totalPrice / 100}</div>
            </div>
            <div className="group">
              <div className="titleBox">支付方式：</div>
              <div className="lineBox"></div>
              <div className="info">
                <ul>
                  <li className={+payType === 5 ? 'active' : ''} onClick={() => { changePayType(5) }}><img src="//cdn-ali-file-shfront.shanhutech.cn/front/web/weixin_1614579638569.png" alt='' /><span>微信支付</span></li>
                  <li className={+payType === 4 ? 'active' : ''} onClick={() => { changePayType(4) }}><img src="//cdn-ali-file-shfront.shanhutech.cn/front/web/zhifubao_1614579638574.png" alt='' /><span>支付宝支付</span></li>
                </ul>
              </div>
            </div>
            <div className="btnBox">
              <button type="button" onClick={() => { payFun() }}>立即购买</button>
            </div>
          </div>
        </div>
      </div>
      {codeFlag && <Code url={codeImg} payType={payType} close={closeCode} />}
    </div>
  )
}

export default Pay;
