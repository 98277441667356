import { alertShow } from './api';
import axios from 'axios';
import OSS from 'ali-oss';
import { getQueryInfo } from './native'

function uuid() {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
}

function getParam(paramName) {
  let paramValue = "";
  let isFound = false;
  if (
    window.location.search.indexOf("?") === 0 &&
    window.location.search.indexOf("=") > 1
  ) {
    let arrSource = unescape(window.location.search)
      .substring(1, window.location.search.length)
      .split("&");
    let i = 0;
    while (i < arrSource.length && !isFound) {
      if (
        arrSource[i].indexOf("=") > 0 &&
        arrSource[i].split("=")[0].toLowerCase() === paramName.toLowerCase()
      ) {
        paramValue = arrSource[i].split("=")[1];
        isFound = true;
      }
      i++;
    }
  }
  return paramValue === "" ? null : paramValue;
}

/**
 * @description 删除更换图片到阿里云服务器
 */

async function clickUpload(e) {
  if (e.target == null || e.target.files.length < 1) return { code: -1 };
  var file = e.target.files[0];
  if (!file) return { code: -1 };

  let re = /image\/jpg|png|jpeg/;
  if (re.test(file.type) === false) {
    return { code: 0, msg: '请选择正确的图片格式' }
  }
  var size = Math.floor(file.size / 1024);
  if (size < 100 || size > 20 * 1024) {
    e.target.value = null
    return { code: 0, msg: '上传图片大小在100K到20M之间' }
  }
  e.target.value = null;
  let _datauRL = await handlerReaderFile(file);
  alertShow('上传图片中,请等待...', 0);
  let data = await uploadServer(_datauRL.dataUrl);
  return data;
}


async function uploadServer(dataurl, type = "base64", w, h) {
  let _config = await getAliConfig();
  return new Promise(resolve => {
    uploadToOssSinger(dataurl, _config, function (result) {
      if (result === null) {
        resolve({ code: 0, message: '上传壁纸失败' });
        return false;
      }
      if (result === 'error') {
        resolve({ code: 0, message: '上传壁纸失败' });
        return false;
      }
      resolve({ code: 200, url: result });
    })
  })
}



function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}



 function handlerReaderFile(file) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise(resolve => {
    reader.onload = readerEvent => {
      resolve({ code: 200, dataUrl: readerEvent.target.result });
    };
    reader.onerror = () => {
      alert('读取文件失败')
    }
  });
}


// 获取阿里云配置
async function getAliConfig() {
  return new Promise((resolve, reject) => {
    axios.jsonp('http://tl.shanhutech.cn/aliyun/oss/getstskey').then(res => {
      if (res.errno === 0 && res.data.status === 200) {
        resolve(res.data);
      } else {
        resolve(null)
      }
    }).catch(() => {
      resolve(null)
    })
  })

}

// 上传单张图片
async function uploadToOssSinger(file, config, cb) {
  let clent = new OSS({
    region: 'oss-cn-beijing',
    accessKeyId: config.AccessKeyId,
    accessKeySecret: config.AccessKeySecret,
    stsToken: config.SecurityToken,
    bucket: 'shtech-diy',
    timeout: 120 * 1000,
  });
  let _result = null;
  _result = await uploadFile2(file, clent);
  cb && cb(_result);
}

// 上传文件方式2
async function uploadFile2(file, clent) {
  if (clent == null) return false;
  if (!file) return false;
  file = dataURLtoBlob(file);
  if (file == null) {
    return null;
  }
  let _date = new Date();
  let _year = _date.getFullYear();
  let _month = _date.getMonth() + 1;
  let _day = _date.getDate();
  let _h = _date.getHours();
  let _m = _date.getMinutes();
  let _s = _date.getSeconds();
  let name = uuid();
  _month = _month < 10 ? '0' + _month : _month;
  try {
    let _name = `tl_shoujike/diypaper_${_year}${_month}/${_day}/${_h + '-' + _m + '-' + _s}-init_${name}.jpg`
    let result = await clent.put(_name, file);
    if (result.res.status === 200) {
      let re = /^(http|https):\/\/([^\\:*<>|"?\r\n/]+)?.com/;
      result.url = result.url.replace(re, 'http://cdn-ali-img-shdiy.shanhutech.cn');
      let _isWhite = await checkoutIsWhitePhoto(result.url);
      if (_isWhite === true) {
        return null;
      }
      return result.url;
    } else {
      return null;
    }
  } catch (e) {
    return 'error'
  }
}

// 打点
function newSetClickCount(_type, _action) {
  let timestamp = Date.parse(new Date());
  timestamp = timestamp / 1000;
  getQueryInfo().then(res => {
    let _mid = res.mid || getParam('mid');
    let _mid2 = res.mid2 || getParam('mid2');
    let _src = `http://s.shanhutech.cn/bizhi?type=${_type}&action=${_action}&mid=${_mid}&mid2=${_mid2}&t=${timestamp}`
    let _img = new Image();
    _img.src = _src;
    return true;
  })

}

// 验证一张图片是否是白图
export function checkoutIsWhitePhoto(url) {
  return new Promise(resolve => {
    if (!url) resolve(true);
    url = url + '?x-oss-process=image/resize,m_lfit,h_100,w_100';
    var image = new Image();
    image.crossOrigin = '';
    image.src = url;
    image.onload = function (e) {
      resolve(false)
    }
    image.onerror = function (e) {
      resolve(true)
    }
  })
}


export {
  getParam,
  clickUpload,
  newSetClickCount
}