import React from 'react';

function Sku(props) {

  return (
    <div className="skuShowPayBox">
      {props.list.map((item, key) => {
        return (
          <div className={+props.id === +item.attr_id ? 'box active' : 'box'} key={key} onClick={() => { props.click(item) }}>{item.attr_name}</div>
        )
      })}

    </div>
  )
}

export default Sku;