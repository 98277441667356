import React from 'react';

function Ios(props) {



  // <!--<img alt="" src="//cdn-ali-file-shfront.shanhutech.cn/front/web/gongzhonghao_1616489452099.png" />-->
  return (
    <div className="otherBox">
      {/*<div className="colorText">
        <p>如何查询物流信息？</p>
  </div>
      <div className="weBox">
        <p>微信扫描下方二维码，关注切壳NOW公众号</p>
        <div className="wechetImg">
          <img alt="" src={props.img} />
        </div>
      </div>*/}
      <div className="showStepBox">
        <div className="leftStep">
          {+props.banner === 1 && <p>您已符合下单返现资格！</p>}
          {+props.banner === 0 &&<p>超多优惠福利等你领取</p>}
          {+props.banner === 1 && <p><small>扫码添加客服微信，马上领取<span>5元</span>微信红包<br />完成任务最高可得<span>40元</span>微信红包</small></p>}
          { +props.banner === 0 && <p><small>扫码添加客服微信，领取超多优惠福利</small></p>}
          <div className="showImageBox">
            <div className="showImage">
              <img alt="" src="//cdn-ali-file-shfront.shanhutech.cn/front/web/code_1639551808600.jpg" />
            </div>
            <div className="showImageText">微信扫一扫 添加客服微信</div>
          </div>
        </div>
        <div className="lineBox" style={{'top': +props.banner === 1 ? '60px' : '30px'}}></div>
        <div className="rightStep">
          <p>查询物流信息</p>
          <p><small>微信扫描下方二维码,关注切壳NOW公众号</small></p>
          <div className="showImageBox" style={{'marginTop': +props.banner === 1 ? '47px' : '0'}}>
            <div className="showImage">
              <img alt="" src={props.img} />
            </div>
            <div className="showImageText">扫码获取物流信息</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ios;