import axios from 'axios';
import server from './server';
import OSS from 'ali-oss';
const isDev = process.env.NODE_ENV === 'development';

axios.jsonp = (url, data) => {
  if (!url)
    throw new Error('url is necessary')
  const callback = 'CALLBACK' + Math.random().toString().substr(9, 18)
  const JSONP = document.createElement('script')
  JSONP.setAttribute('type', 'text/javascript')

  const headEle = document.getElementsByTagName('head')[0]

  let ret = '';
  if (data) {
    if (typeof data === 'string')
      ret = '&' + data;
    else if (typeof data === 'object') {
      for (let key in data)
        ret += '&' + key + '=' + encodeURIComponent(data[key]);
    }
    ret += '&_time=' + Date.now();
  }
  JSONP.src = `${url}?sts_type=shtech-diy&callback=${callback}${ret}`;
  return new Promise((resolve, reject) => {
    window[callback] = r => {
      resolve(r)
      headEle.removeChild(JSONP)
      delete window[callback]
    }
    headEle.appendChild(JSONP)
  })

}

export function alertShow(msg, duration) {
  const Div = document.getElementById("alertBox");
  const Body = document.querySelector('body');
  if (Div) {
    Body.removeChild(Div);
  }

  const div = document.createElement('div');
  div.innerText = msg;
  div.setAttribute('class', 'showAlert')
  div.setAttribute('id', 'alertBox')

  Body.appendChild(div);
  if (duration !== 0) {
    setTimeout(() => {
      const Div = document.getElementById("alertBox");
      Body.removeChild(Div);
    }, 3000)
  }
}
alertShow.close = function () {
  const Div = document.getElementById("alertBox");
  const Body = document.querySelector('body');
  if (Div) {
    Body.removeChild(Div);
  }
}



export function setClickCount(_type, _action, _mid, _mid2) {
  let timestamp = Date.parse(new Date());
  timestamp = timestamp / 1000;
  let img = new Image();

  img.onload = () => {

  }

  img.src = '//ss.shanhutech.cn/bizhi?type=' + _type + '&action=' + _action + '&mid1=' + _mid + '&mid2=' + _mid2 + '&t=' + timestamp
}




export function getCatList(cid, pageno) {
  return server.get(`http://bizhi.shanhutech.cn/live/diy/GetImgList?cat_id=${cid}&pageno=${pageno}&count=20`)
}


export function createqrode(img_url, diy_type) {
  let url = isDev ? '/bizhidiy/diy/GetQrcodeForDiy' : 'http://www.shanhutech.cn/bizhidiy/diy/GetQrcodeForDiy'
  return new Promise(resolve => {
    axios.jsonp(url, { img_url, diy_type }).then(res => {
      if (res.errno === 0) {
        resolve(res.data)
      } else {
        resolve('')
      }
    }).catch(error => {
      resolve('')
    })
  })
}


/**
 * 获取品牌
 */

export function getProductList() {
  return new Promise((resolve, reject) => {
    let url = isDev ? '/product/getproductlist?cat_id=1' : 'http://pc.uyoungdiy.com/pc/product/getproductlist?cat_id=1';
    server.get(url).then(res => {
      if (Array.isArray(res.list) && res.list.length > 0) {
        resolve(res)
      } else {
        resolve(false)
      }
    })
  })
}

/**
 * 获取手机壳地图
 */

export function getPhoneImage(req) {
  let url = isDev ? 'product/getproduct' + req : 'http://pc.uyoungdiy.com/pc/product/getproduct' + req;
  return new Promise((resolve, reject) => {
    server.get(url,).then(res => {
      if (res && res.product_id) {
        resolve(res)
      } else {
        resolve(false)
      }

    })
  })
}

/**
 * 
 * 获取url参数
 */

export function getUrlParams(name, str) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  const r = str.substr(1).match(reg);
  if (r != null) return decodeURIComponent(r[2]); return null;
}

/**
 * 获取ali配置
 */

export function getAliConfig() {
  let url = isDev ? '/aliyun/oss/getstskey?sts_type=shtech-diy' : 'http://pc.uyoungdiy.com/tl/aliyun/oss/getstskey?sts_type=shtech-diy';
  return new Promise((resolve, reject) => {
    server.get(url).then(res => {
      if (res.status) {
        resolve(res);
      }
    })
  })
}


/**
 * 
 * 阿里上传
 */

export async function uploadToOssSinger(file, config, cb) {
  let clent = new OSS({
    region: 'oss-cn-beijing',
    accessKeyId: config.AccessKeyId,
    accessKeySecret: config.AccessKeySecret,
    stsToken: config.SecurityToken,
    bucket: 'shtech-diy',
    timeout: 10 * 1000,
  });
  let _result = null;
  let exfat = config.exfat;
  _result = await uploadFile2(file, clent, exfat);
  console.log(1,_result)
  cb && cb(_result);
}

function uuid() {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
}


async function uploadFile2(file, clent, exfat) {
  exfat = exfat || 'png'
  if (clent == null) return false;
  if (!file) return false;
  if (file == null) {
    return null;
  }
  let _date = new Date();
  let _year = _date.getFullYear();
  let _month = _date.getMonth() + 1;
  let _day = _date.getDate();
  let _h = _date.getHours();
  let _m = _date.getMinutes();
  let _s = _date.getSeconds();
  let name = uuid();
  _month = _month < 10 ? '0' + _month : _month;
  try {
    let result = await clent.put(`tl_shoujike/${_year}${_month}/${_day}/${_h + '-' + _m + '-' + _s}-init_${name}.${exfat}`, file);
    if (result.res.status === 200) {
      let re = /^(http|https):\/\/([^\\:*<>|"?\r\n/]+)?.com/;
      result.url = result.url.replace(re, 'http://cdn-ali-img-shdiy.shanhutech.cn');
      return result.url;
    } else {
      return null;
    }
  } catch (e) {
    return 'error'
  }
}


/**
 * 
 * 获取banner 材质
 */

export function getBannerInfo(req) {
  let url = isDev ? '/product/getproductattributelist' + req : 'http://pc.uyoungdiy.com/pc/product/getproductattributelist' + req;
  return new Promise((resolve, reject) => {
    server.get(url).then(res => {
      if (res && res.sku_list) {
        resolve(res);
      } else {
        resolve(false);
      }
    })
  })
}


/**
 * 
 * 省市区三级联动
 */

export function getThirdChoose(req) {
  let url = isDev ? '/api/getregions' + req : 'http://pc.uyoungdiy.com/pc/api/getregions' + req;
  return new Promise(resolve => {
    server.get(url).then(res => {
      if (Array.isArray(res)) {
        resolve(res);
      } else {
        resolve(false);
      }
    })
  })
}


/**
 * 
 * 更改数量计算价格
 */

export function changeNumPrice(req) {
  let url = isDev ? '/www/weborder/getpreorderinfoforpc' : 'http://pc.uyoungdiy.com/pc/weborder/getpreorderinfoforpc';
  return new Promise(resolve => {
    server.post(url, req).then(res => {
      if (res) {
        resolve(res);
      } else {
        resolve(false)
      }

    })
  })
}


/**
 * 
 * 提交订单
 */

export function createOderPay(req) {
  let url = isDev ? '/weborder/createorderforpc' : 'http://pc.uyoungdiy.com/pc/weborder/createorderforpc';
  return new Promise(resolve => {
    server.post(url, req).then(res => {
      if (res) {
        resolve(res)
      } else {
        resolve(false);
      }
    })
  })
}



/**
 * 
 * 轮询查询订单状态
 */


export function getOrderState(id) {
  let url = isDev ? '/weborder/getorderstatus' : 'http://pc.uyoungdiy.com/pc/weborder/getorderstatus';
  return new Promise(resolve => {
    server.get(url + '?order_id=' + id).then(res => {
      if (res) {
        resolve(res);
      } else {
        resolve(false);
      }
    })
  })
}

function dataURLtoBlob(dataurl) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}


export async function base64ToSvg(dataurl) {
  return new Promise((resolve) => {
    var blob = dataURLtoBlob(dataurl);
    resolve(blob);
  });
}


export function showWechartCode(req) {
  let url = isDev ? '/bizhidiy/diy/GetQrcode?' + req : 'http://pc.uyoungdiy.com/shanhu/bizhidiy/diy/GetQrcode?' + req
  return new Promise(resolve => {
    server.get(url).then(res => {
      if (res) {
        resolve(res)
      } else {
        resolve(false)
      }
    })
  })
}



