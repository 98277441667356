import React from 'react';
import './load.css'
function Loading() {
  return (
    <div className="showLoad">
      <img alt="" src="//cdn-ali-file-shfront.shanhutech.cn/front/web/bird_1615450460650.gif"/>
    </div>
  )
}

export default Loading;