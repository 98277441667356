import React from 'react';

import './style.scss';
const loadImg = require('../../assets/images/load.png');

function Qrcode({ url, type ,closeQrCode}) {
  type = type || '手机壳'
  return (
    <div className="qr-code">
      <div className="code-mask"></div>
      <div className="code-content">
        <div className="close" onClick={closeQrCode}></div>
        <p>
          {/* 喜欢这张图片吗？<br/> */}
          微信扫描下方二维码，关注【切壳NOW】公众号<br/>
          定制您的专属{type}！
        </p>
        <div className="code-bg">
          <div className="code-image">
            {url ? <img alt="二维码" src={url}/> : <img alt="加载" src={loadImg} className="load"/>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Qrcode;