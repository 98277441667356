import React, { useEffect, useState } from 'react';
import './showAddress.css'
import { getThirdChoose } from '../../assets/scripts/api';
import { newSetClickCount } from '../../assets/scripts/common'
import { Select } from 'antd';
const {Option} = Select
function Address(props) {
  const [provinceData, setProvince] = useState([{code:0,name:'选择省份'}]);
  const [cityData, setCityData] = useState([{code:0,name:'选择城市'}]);
  const [areaData, setAreaData] = useState([{code:0,name:'选择区/县'}])
  const [provinceId, setProvinceId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [areaId, setAreaId] = useState(0);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [street, setStreet] = useState('');
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [defaultProvince, setDefaultProvince] = useState('选择省份');
  const [defaultCity, setDefaultCity] = useState('选择城市');
  const [defaultArea, setDefaultArea] = useState('选择区/县');
  const changeProvince = (name,item) => {

    if(!name) return
    setProvinceId(provinceData[item.key]['code']);
    setCityId(0);
    setAreaId(0);
    setDefaultProvince(name)
    setDefaultCity('选择城市');
    setDefaultArea('选择区/县')
    getThirdChoose(`?page_from=qiekenao&region_id=2&code=${provinceData[item.key]['code']}&oreder_from=qiekenao`).then(res => {
      if (res) {
        setCityData([{code:0,name:'选择城市'},...res]);
      }
    })
  }

  const changeCity = (name,item) => {
    if (!name) return;
    setCityId(cityData[item.key]['code']);
    setDefaultCity(name)
    setAreaId(0)
    setDefaultArea('选择区/县')
    getThirdChoose(`?page_from=qiekenao&region_id=3&code=${cityData[item.key]['code']}&oreder_from=qiekenao`).then(res => {
      if (res) {
        setAreaData([{code:0,name:'选择区/县'},...res]);
      }
    })
  }

  const changeArea = (name,item) => {
    setDefaultArea(name)
    setAreaId(areaData[item.key]['code']);
  }

  const changeName = (e) => {
    setName(e.target.value);
  }

  const changePhone = (e) => {
    setPhone(e.target.value);
  }

  const changeStreet = (e) => {
    setStreet(e.target.value);
  }

  const submitFun = () => {
    const reg = /^1\d{10}$/;

    if (!name) {
      setIsError(true);
      setErrorText('请填写姓名');
      return;
    }

    if (name.length > 12) {
      setIsError(true);
      setErrorText('收货人姓名不能超过12个字');
      return;
    }

    if (!phone || !reg.test(phone)) {
      setIsError(true);
      setErrorText('请您输入有效手机号');
      return;
    }

    if (+provinceId === 0 || +cityId === 0 || +areaId === 0) {
      setIsError(true);
      setErrorText('请选择省市区地址')
      return;
    }

    if (!street || street.length > 100) {
      setIsError(true);
      setErrorText('请输入详细地址')
      return;
    }

    setIsError(false)

    const provinceIndex = provinceData.findIndex(item => {
      return +item.code === +provinceId
    })

    const cityIndex = cityData.findIndex(item => {
      return +item.code === +cityId
    })

    const areaIndex = areaData.findIndex(item => {
      return +item.code === +areaId
    })

    const obj = {
      name: name,
      phone: phone,
      province: provinceData[provinceIndex]['name'],
      provinceId: provinceData[provinceIndex]['code'] || provinceId,
      city: cityData[cityIndex]['name'],
      cityId: cityData[cityIndex]['code'],
      area: areaData[areaIndex]['name'],
      areaId: areaData[areaIndex]['code'],
      street: street
    }
    newSetClickCount('diy_pc', 'phonecase_texture_addressdone');
    localStorage.setItem('addressInfo', JSON.stringify(obj));
    props.click(false)
  }

  useEffect(() => {
    let item;
    if (localStorage.getItem('addressInfo')) {
      item = JSON.parse(localStorage.getItem('addressInfo'));
      setName(item.name);
      setPhone(item.phone);
      setStreet(item.street);
      setDefaultProvince(item.province);
      setProvinceId(item.provinceId);
      setDefaultCity(item.city);
      setCityId(item.cityId);
      setDefaultArea(item.area);
      setAreaId(item.areaId)
    }
    setIsError(false)
    getThirdChoose(`?page_from=qiekenao&region_id=1&code=&oreder_from=qiekenao`).then(res => {
      if (res) {
        setProvince([...res]);
        if (item && item.provinceId) {
          getThirdChoose(`?page_from=qiekenao&region_id=2&code=${item.provinceId}&oreder_from=qiekenao`).then(res => {
            setCityData([...res]);
            getThirdChoose(`?page_from=qiekenao&region_id=3&code=${item.cityId}&oreder_from=qiekenao`).then(res => {
              setAreaData([...res]);
            })
          })
        }

      }
    })
  }, [])
  return (
    <div className="addAddress">
      <div className="showAddress">
        <div className="addressBox">
          <div className="closeBox">
            <span onClick={() => { props.click(false) }}></span>
          </div>
          <div className="titleBox">
            <h3>添加收货地址</h3>
          </div>
          <div className="formBox">
            <form onSubmit={(e) => { e.preventDefault(); submitFun(); }}>
              <div className="group">
                <label>收货人</label>
                <input type="text" placeholder="请输入收货人姓名" value={name} onChange={(e) => { changeName(e) }} maxLength="12" />
              </div>
              <div className="group">
                <label>联系方式</label>
                <input type="text" placeholder="请输入收货人手机号" value={phone} onChange={(e) => { changePhone(e) }} maxLength="11"/>
              </div>
              <div className="group">
                <label>所在地区</label>
                { provinceData && provinceData.length > 0 &&
                  <Select className="province" onChange={(value,item) => { changeProvince(value,item) }} style={{ width: 131, height: 31, marginRight: 10 }} value={defaultProvince}>

                  {
                    provinceData.map((item, key) => {
                      return (
                        <Option value={item.name} key={key}>{item.name}</Option>
                      )
                    })
                  }

                </Select>
                }
                <Select onChange={(value,item) => { changeCity(value,item) }} style={{width:131,height:31,marginRight:10}} value={defaultCity}>
                  {
                    cityData && cityData.length > 0 && cityData.map((item, key) => {
                      return (
                        <Option value={item.name} key={key}>{item.name}</Option>
                      )
                    })
                  }
                </Select>
                <Select onChange={(value,item) => { changeArea(value,item) }} style={{ width: 131, height: 31 }} value={defaultArea}>
                  {
                    areaData && areaData.length > 0 && areaData.map((item, key) => {
                      return (
                        <Option value={item.name} key={key}>{item.name}</Option>
                      )
                    })
                  }
                </Select>
              </div>
              <div className="group">
                <label style={{position:'relative',top:'-55px' }}>详细地址</label>
                <textarea value={street} onChange={(e) => { changeStreet(e) }} maxLength="100"></textarea>
              </div>
              {isError && <div className="group">
                <label></label>
                <span className="red" >{errorText} </span>
              </div>}

              <div className="group">
                <button type="submit" onClick={(e) => { e.preventDefault(); submitFun(); }}>保存地址</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Address
