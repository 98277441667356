import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import App from '../../App';
import Make from '../../Make/make';
import Sku from '../../SKU/sku'
import Pay from '../../Pay/pay'
import Result from '../../Result/result'
function AppLink() {
  return (
    <BrowserRouter>
      <Route path='/' exact component={App}></Route>
      <Route path='/make' exact component={Make}></Route>
      <Route path='/sku' exact component={Sku}></Route>
      <Route path='/pay' exact component={Pay}></Route>
      <Route path='/result' exact component={Result}></Route>
    </BrowserRouter>
  )
}

export default AppLink;