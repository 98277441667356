import React from 'react';

function Right(props) {

  return (
    <div className="rightBox">
      <div className="listBox">
        <div className="titleBox">
          <h3>{props.name}</h3>
        </div>
        <div className="showList">
          {props.list.map((item, key) => {
            return (
              <div className="box" key={item.id} onClick={() => props.click(item)}>{item.name}</div>
            )
          })}

        </div>
      </div>
    </div>
  )
}

export default Right;