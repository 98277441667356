import React from 'react';


function Load() {
  return (
    <div className="loadBox">
      <div className="loadBj"></div>
      <div className="load">
        <p>图片上传中...</p>
      </div>
    </div>
  )
}

export default Load;