import React, { useEffect } from 'react';
import { newSetClickCount } from '../../../assets/scripts/common'
import QRCode from 'qrcode.react'

function Code(props) {
  useEffect(() => {
    newSetClickCount('diy_pc', 'phonecase_payment_show');
  }, [])
  return (
    <div className="codeBox">
      <div className="codeBj"></div>
      <div className="showCode">
        <div className="closeBox"><span onClick={() => { props.close() }}></span></div>
        <div className="codeShow">
          <h3>{+props.payType === 5 ? '微信' : '支付宝'}扫描二维码完成付款</h3>
          <div className="codeImg">
            {props.url && <QRCode value={props.url}></QRCode>}
            {!props.url && <img src="//cdn-ali-file-shfront.shanhutech.cn/front/web/lo_1616411293210.gif" alt="" />}
            {!props.url && <p>支付中···</p>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Code;

