import { useEffect, useState, useRef } from 'react';
export function useWindowScroll(ele) {
  const [state, setState] = useState({
    x: 0,
    y: 0,
  });

  const dirRef = useRef(0);

  useEffect(() => {
    let _ele = ele || window
    const handler = (e) => {
      let dir = e.target.scrollTop > dirRef.current ? 'down' : 'up';
      let _down = e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight);
      dirRef.current = e.target.scrollTop;

      setState({
        top: e.target.scrollTop,
        left: e.target.scrollLeft,
        dir,
        distance: _down
      });
    };
    
    _ele.addEventListener('scroll', handler, {
      capture: false,
      passive: true,
    });
    return () => {
      _ele.removeEventListener('scroll', handler);
    };
  });
  return state;
}