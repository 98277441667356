import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import Slider from 'rc-slider';
import Draggable from 'react-draggable';
import 'rc-slider/assets/index.css';
import './make.css';
import { getPhoneImage, getUrlParams, getAliConfig, uploadToOssSinger, alertShow, base64ToSvg } from '../assets/scripts/api';
import { registerFunction, unRegisterFunction, getImageBase64 } from '../assets/scripts/native'
import html2canvas from "html2canvas";
import Load from './components/load'
import { newSetClickCount, checkoutIsWhitePhoto } from '../assets/scripts/common'
function Make(props) {
  const setPhone = useRef(null);
  const phoneBox = useRef(null);
  const imageBox = useRef(null);
  //slider 初始化配置
  const mark = {
    50: {
      style: {
        color: 'blue',
      }
    }
  }
  const [rotate, setRotate] = useState(0);
  const [defaultRotate, setDefaultRotate] = useState(50);
  const [localX, setLocalX] = useState(0);
  const [localY, setLocalY] = useState(0);
  const [scales, setScales] = useState(1);
  const [defaultScales, setDefaultScales] = useState(50);
  const [name, setName] = useState('')
  const [productId, setProductId] = useState(0);
  const [diyParams, setDiyParams] = useState({});
  const [makeImage, setMakeImage] = useState('');
  const hanleDrag = (e, data) => {
    setLocalX(data.x);
    setLocalY(data.y);
  }
  const [isLoad, setLoad] = useState(false);
  const [catId, setCatId] = useState(0);

  const [isBig, setIsBig] = useState(true);
  // const [isChangeSize, setIsChangeSize] = useState(false)
  const hanleDragEnd = (e) => {
    setPhone.current.style = 'transform:translate(0,0)'
  }


  const setsRotate = (e) => {
    let num = 0
    if (+e <= 50) {
      num = (3.6 * (50 - e))

    } else {
      num = - (3.6 * (e - 50));
    }
    setDefaultRotate(e)
    setRotate(num)
  }

  const setBigSmall = (e) => {
    let num = 0;
    if (+e <= 50) {
      num = (50 - e) / 10 + 1;

    } else {
      num = (1 - (e - 50) / 100) <= 0 ? 0 : (1 - (e - 50) / 100);
    }
    setScales(num)
    setDefaultScales(e)
  }


  const changeFile = (e) => {
    const target = e.target.files[0];
    if (target.size < 100 * 1024 || target.size > 20 * 1024 * 1024) {
      alertShow('上传图片应在100K-20M之间', 2000);
      return
    }


    setLoad(true);
    newSetClickCount('diy_pc', 'phonecase_edit_upload');
    getAliConfig().then(res => {
      uploadToOssSinger(target, res, function (res) {

        if (res) {
          checkoutIsWhitePhoto(res).then(flag => {
            if (!flag) {
              setLoad(false);
              setMakeImage(res);
              setRotate(0);
              setScales(1);
              setLocalX(0);
              setLocalY(0);
              setDefaultRotate(50);
              setDefaultScales(50);
              sessionStorage.setItem('img', res);
            } else {
              setLoad(false);
              alertShow('上传失败，请重新上传');
            }
          })

        } else {
          setLoad(false);
          alertShow('上传失败，请重新上传');
        }

      })
    })
  }


  const hanleCanvas = (targetCanvas) => {
    return new Promise(resolve => {
      targetCanvas.toBlob((blob) => {
        if (blob.size < 10000) {
          return
        } else {
          getAliConfig().then(res => {
            uploadToOssSinger(blob, res, function (res) {
              if (res) {
                resolve(res);
              } else {
                alertShow('上传失败，请重新上传');
                setLoad(false);
              }

            })
          })
        }
      })
    })
  }

  const clickFun = async () => {
    let _options = {
      useCORS: true,
      allowTaint: false,
      backgroundColor: null,
      taintTest: false,
      scale: 3
    }
    try {
      setLoad(true);
      // changeImageSize(false)
      //制作图
      const imageCanvas = await html2canvas(imageBox.current, _options);
      const makesImage = await hanleCanvas(imageCanvas);
      if (!makesImage) {
        setLoad(false);
        return

      }
      //效果图
      const phoneCanvas = await html2canvas(phoneBox.current, _options);
      const phoneImage = await hanleCanvas(phoneCanvas);
      setLoad(false);
      if (!phoneImage) return
      newSetClickCount('diy_pc', 'phonecase_edit_next');
      // props.history.push({
      //   pathname: `/pay?productId=${productId}&ori_url=${makeImage}&print_url=${makeImage}&show_url=${phoneImage}&cat_0=${catId}&name=${name}`
      // })

      window.location.href = `/pay?productId=${productId}&ori_url=${makeImage}&print_url=${makesImage}&show_url=${phoneImage}&cat_0=${catId}&name=${name}&smallui=${getUrlParams('smallui', props.location.search)}&banner=${getUrlParams('banner', props.location.search)}`;

    } catch (error) {
      setLoad(false);
      console.log(error)
    }
  }


  // const changeImageSize = (flag) => {
  //   setIsChangeSize(flag)
  // }


  useEffect(() => {
    if (window && window.external && 'Execute' in window.external) {
      unRegisterFunction();
      registerFunction('on_diy', (res) => {
        const data = JSON.parse(res);
        if (data.wp_type === 'static_wp') {
          window.location.href = `/sku?url=${data.url}&smallui=${getUrlParams('smallui', props.location.search)}`;
        } else {
          window.location.href = `/sku?url=none&smallui=${getUrlParams('smallui', props.location.search)}&path=${data.url}`;
        }
      })
    }
    const small = getUrlParams('smallui', props.location.search);
    setIsBig(+small === 1 ? true : false);
    newSetClickCount('diy_pc', 'phonecase_edit_show');
    getAliConfig()
    setName(getUrlParams('phoneName', props.location.search));
    setCatId(getUrlParams('cat_0', props.location.search));
    let url =sessionStorage.getItem('img') || getUrlParams('url', props.location.search);
    if (url !== 'none') {
      setMakeImage(url);
    } else {
      getImageBase64(getUrlParams('path', props.location.search)).then(result => {
        base64ToSvg(result).then(data => {
          console.log(data);
          getAliConfig().then(res => {
            uploadToOssSinger(data, res, function (_res) {
              setMakeImage(_res)
            })
          })
        })

      })
    }

    getPhoneImage(props.location.search).then(res => {
      if (res) {
        setProductId(res.product_id);
        setDiyParams(JSON.parse(res.diy_params))
      }
    });

    if (!HTMLCanvasElement.prototype.toBlob) {
      Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
        value: function (callback, type, quality) {
          var canvas = this;
          setTimeout(function () {
            var binStr = atob(canvas.toDataURL(type, quality).split(',')[1]);
            var len = binStr.length;
            var arr = new Uint8Array(len);
            for (var i = 0; i < len; i++) {
              arr[i] = binStr.charCodeAt(i);
            }

            callback(new Blob([arr], { type: type || 'image/png' }));
          });
        }
      });
    }

  }, [props.location.search])

  return (
    <div className={isBig ? 'content makeBox small' : 'content makeBox'}>
      <div className="topBox">
        <div className="leftBox">
          <div className="retrunBox"><Link to={`/sku${props.location.search}`}><span className="icon"></span>{name && <div className="textBox">{name}</div>}</Link></div>


        </div>
        <div className="rightBox">
          <input type="file" accept=" image/jpeg, image/jpg, image/png" onChange={(e) => { changeFile(e); e.target.value = '' }} />
          <button type="button">上传图片</button>
        </div>
      </div>
      <div className="showBox">
        <div className="rotateBox">
          <div className="titleBox">
            旋转图片
          </div>
          <div className="rotate">
            <span>顺</span>
            <Slider vertical reverse defaultValue="50" trackStyle={[{ backgroundColor: '#e9e9e9' }]} marks={mark} onChange={(e) => { setsRotate(e) }} className="slider" value={defaultRotate} />
            <span>逆</span>
          </div>
        </div>
        <div className="demoBox">
          <div className="textBox">按住鼠标左键拖动图片</div>
          <div className="showMake" ref={phoneBox} >
            <div className="phoneBox" >
              {Array.isArray(diyParams.baseimg) && <img alt="" src={diyParams['baseimg'][0]['url']} draggable="false" />}
            </div>
            <Draggable onDrag={(e, data) => hanleDrag(e, data)} onStop={(e) => { hanleDragEnd(e) }} >
              <div className="setPhone" ref={setPhone}></div>
            </Draggable>
            <div className="imageBox" ref={imageBox}>
              {Array.isArray(diyParams.baseimg) && <img alt="" draggable="false" src={makeImage} style={{ transform: 'rotate(' + rotate + 'deg) scale(' + scales + ')', left: localX ? localX + 'px' : Math.ceil(195 - (195 * diyParams['baseimg'][0]['w'] * 100 / 10000)) + 'px', top: localY + 'px' }} crossorigin="anonymous"/>}

            </div>
          </div>
          <div className="textBox">尽量让图片铺满全壳，以达到最佳打印效果</div>
        </div>
        <div className="zoomBox">
          <div className="rotate">
            <span>大</span>
            <Slider vertical reverse defaultValue="50" trackStyle={[{ backgroundColor: '#e9e9e9' }]} marks={mark} className="slider" onChange={(e) => { setBigSmall(e) }} value={defaultScales} />
            <span>小</span>
          </div>
          <div className="titleBox">
            缩放图片
          </div>
        </div>
      </div>
      <div className="btnBox">
        <button type="button" onClick={() => { clickFun() }}>下一步</button>
      </div>
      {isLoad && <Load />}
    </div>
  )
}

export default Make;
