import React, { useEffect, useState, useCallback } from 'react';
import { getProductList, getUrlParams } from '../assets/scripts/api'
import { registerFunction, unRegisterFunction, backPage } from '../assets/scripts/native'
import { newSetClickCount } from '../assets/scripts/common'
import Left from './components/LeftNav/left';
import Right from './components/Right/right';
import More from './components/Right/more'
import Loading from '../components/Loading/loading'
import './sku.css'

function Sku(props) {

  const [listData, setListData] = useState([])
  const [phoneData, setPhoneData] = useState([])
  const [isList, setIsList] = useState(true);
  const [name, setName] = useState('');
  const [brand, setBrand] = useState(0);
  const [isBig, setIsBig] = useState(true);
  const [load, setLoad] = useState(true);
  //切换左边
  const changeLeft = (item) => {
    const index = listData.findIndex(_item => {
      return +item.id === _item.id;
    })
    let flag = listData[index]['list'].findIndex(item => {
      return Array.isArray(item.list);
    });
    flag = flag > -1 ? true : false;
    setIsList(flag)
    setPhoneData(listData[index]['list']);
    setName(listData[index]['name'])
    setBrand(listData[index]['id']);
    setTimeout(() => { document.querySelector('.rightBox').scrollTop = 0; }, 200)
  }


  const backPageFun = () => {
    const isChangeFlag = sessionStorage.getItem('ischange');

    if (+isChangeFlag === 1) {
      backPage()
    } else {
      sessionStorage.setItem('ischange', 0)
      window.location.href = `http://pc.uyoungdiy.com${props.location.search}`;
    }

  }

  //选择机型
  const choosePhone = useCallback((item, _item) => {
    // props.history.push({
    //   pathname: `/make?cat_0=1&cat_1=${brand}&cat_2=${item.id}&cat_3=${_item ? _item.id : 0}&brand=${name}&phoneName=${_item ? _item.name : item.name}&url=${getUrlParams('url', props.location.search)}`,
    // })
    if (getUrlParams('url', props.location.search) !== 'none') {
      window.location.href = `/make?cat_0=1&cat_1=${brand}&cat_2=${item.id}&cat_3=${_item ? _item.id : 0}&brand=${name}&phoneName=${_item ? _item.name : item.name}&url=${getUrlParams('url', props.location.search)}&smallui=${getUrlParams('smallui', props.location.search)}&banner=${getUrlParams('banner', props.location.search)}`
    } else {
      window.location.href = `/make?cat_0=1&cat_1=${brand}&cat_2=${item.id}&cat_3=${_item ? _item.id : 0}&brand=${name}&phoneName=${_item ? _item.name : item.name}&url=${getUrlParams('url', props.location.search)}&smallui=${getUrlParams('smallui', props.location.search)}&path=${getUrlParams('path', props.location.search)}&banner=${getUrlParams('banner', props.location.search)}`
    }

  }, [brand, name, props.location.search])


  useEffect(() => {
    if (window && window.external && 'Execute' in window.external) {
      unRegisterFunction();
      registerFunction('on_diy', (res) => {
        const data = JSON.parse(res);
        sessionStorage.setItem('ischange', 1);
        setTimeout(() => {
          if (data.wp_type === 'static_wp') {
            window.location.href = `/sku?url=${data.url}&smallui=${getUrlParams('smallui', props.location.search)}`;
          } else {
            window.location.href = `/sku?url=none&smallui=${getUrlParams('smallui', props.location.search)}&path=${data.url}`;
          }
        }, 1000)

      })
    }
    sessionStorage.removeItem('img')
    const small = getUrlParams('smallui', props.location.search);
    setIsBig(+small === 1 ? true : false)
    newSetClickCount('diy_pc', 'phonecase_model_show');
    getProductList().then(res => {
      if (res) {
        setListData(res.list);
        setLoad(false)
        let flag = res['list'][0]['list'].findIndex(item => {
          return Array.isArray(item.list);
        });
        flag = flag > -1 ? true : false;
        setIsList(flag)
        setPhoneData(res['list'][0]['list']);
        setName(res['list'][0]['name'])
        setBrand(res['list'][0]['id']);
      }
    })
  }, [props.location.search])
  return (
    <div className={isBig ? 'content skuBox small' : 'content skuBox'} >
      <div className="topBox">
        <div className="left">
          <div className="retrunBox"><a href={`http://pc.uyoungdiy.com${props.location.search}`}><span className="icon"></span>        <div className="textBox">选择您的手机品牌&gt;型号</div></a></div>

        </div>

        <div className="right">
          <a href="#!" onClick={() => { backPageFun() }}><span></span></a>
        </div>
      </div>
      <div className="showSku">
        {listData && listData.length > 0 && <Left list={listData} click={changeLeft} />}
        {phoneData && phoneData.length > 0 && !isList && <Right list={phoneData} name={name} click={choosePhone} />}
        {phoneData && phoneData.length > 0 && isList && <More list={phoneData} click={choosePhone} />}
      </div>
      {load && <Loading />}
    </div>
  )
}

export default Sku;
