import React from 'react';

function More(props) {
  return (
    <div className="rightBox">
      <div className="listBox">
        {props.list.map((item, key) => {
          return (
            <div key={item.id}>
              <div className="titleBox" ><h3>{item.name}</h3></div>
              <div className="showList">
                {
                  item.list.map((_item, _key) => {
                    return (
                      <div className="box" key={_item.id} onClick={() => { props.click(item, _item) }}> { _item.name}</div>
                    )
                  })
                }
              </div>
            </div>


          )
        })}
      </div>
    </div >
  )
}

export default More;