export function webReady() {
  return new Promise(resolve => {
    if (window && window.external && 'Execute' in window.external) {
      window.external.Execute('ready', (executeId, errorCode, strResult) => {
        if (+errorCode === 0) {
          resolve(true)
        }
      }, '')
    }
  })
}

export function getImageBase64(path) {
  return new Promise(resolve => {
    if (window && window.external && 'Execute' in window.external) {
      window.external.Execute('get_file_content', (executeId, errorCode, strResult) => {
        if (+errorCode === 0) {
          const data = JSON.parse(strResult)
          resolve(data.data);
        }
      }, JSON.stringify({ path }))
    }
  })
}

export function backPage() {
  return new Promise(resolve => {
    if (window && window.external && 'Execute' in window.external) {
      window.external.Execute('back_page', (executeId, errorCode, strResult) => {
        if (+errorCode === 0) {
          resolve(true)
        }
      }, '')
    }
  })
}

export function registerFunction(name, cb) {
  if (window && window.external && 'Execute' in window.external) {
    window.external.RegisterFunction(function (res) {
      cb(res);
    }, name)
  }
}

export function unRegisterFunction() {
  if (window && window.external && 'UnregisterAllFunctionEx' in window.external) {
    window.external.UnregisterAllFunctionEx()
  }
}


export function getQueryInfo() {
  return new Promise(resolve => {
    if (window && window.external && 'Execute' in window.external) {
      window.external.Execute('query_info', (executeId, errorCode, strResult) => {
        if (+errorCode === 0) {
          resolve(JSON.parse(strResult));
        }
      }, '')
    }
  })
}