import React,{useEffect,useState} from 'react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
SwiperCore.use([Autoplay, Pagination]);
function Banner(props) {
  const [config, setConfig] = useState()
  const [flag, setFlag] = useState(false)
  
  const change = (item) => {
    if (flag) {
      item.slideTo(1)
    }
    setFlag(false)
  }

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setFlag(true)

    setConfig({
      slidesPerView: 1,
      spaceBetween: 0,
      autoplay: {
        delay: 8000,
        disableOnInteraction:false
      },
      pagination: {
        clickable:true
      },
      loop: true,
      initialSlide: 1,
      observer:true,//修改swiper自己或子元素时，自动初始化swiper
      observeParents:true,//修改swiper的父元素时，自动初始化swiper
      onSwiper: (swiper) => {
        // console.log(swiper)
        // swiper.observer.destroy()
      }
    })
  },[props.list,props.skuActiveId])
  return (
    <div className="bannerBox">
      {config && <Swiper {...config} onObserverUpdate={(swiper)=>{change(swiper)}}>
      {props.list.map((item, key) => {
        return (
          <SwiperSlide key={key}><img alt="" src={item.img_url} /></SwiperSlide>
        )
      })}
    </Swiper>}
      
    </div>
  )
}

export default Banner;