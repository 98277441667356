import { useState, useEffect, useCallback } from 'react';
import axios from 'axios'

const BASE_URL = "";


export function useDingzhiAPI(path, { initialData = null, converter = (data) => data }) {

  const [data, setData] = useState(initialData);
  const convertData = useCallback(converter, [])

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios(`${BASE_URL}${path}`);
      const data = response.data;
      setData(convertData(data));
    }
    fetchData();
  }, [convertData, path])
  return data.data;
}
