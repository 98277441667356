import React from 'react';
import ReactDOM from 'react-dom';
// import App from './App';
import AppLink from './assets/router/router'
import * as serviceWorker from './serviceWorker';
import './index.css'

ReactDOM.render(
  <AppLink />,
  document.getElementById('root')
);

serviceWorker.unregister();
