import React, { useState } from 'react';

function Left(props) {

  const [index, setIndex] = useState(0);

  const clickFun = (item, key) => {
    setIndex(key)
    props.click(item)
  }

  return (
    <div className="leftBox">
      <ul>
        {props.list.map((item, key) => {
          return (
            <li key={item.id} className={+key === +index ? 'active' : ''} onClick={() => { clickFun(item, key) }}>
              <img alt="" src={item.icon} /><span>{item.name}</span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Left;