import React, { useEffect, useState } from 'react';
import { getUrlParams, showWechartCode } from '../assets/scripts/api'
import { newSetClickCount } from '../assets/scripts/common'
import Android from './components/Android';
import Ios from './components/Ios'
import { registerFunction, unRegisterFunction, getQueryInfo } from '../assets/scripts/native'
import './result.css'

function Result(props) {
  const [isAndroid, setIsAndroid] = useState(false);
  const [isBig, setIsBig] = useState(true);
  const [imgUrl, setImg] = useState('');
  const [isBanner, setIsBanner] = useState(0)
  const backPageFun = () => {
    getQueryInfo().then(res => {
      window.location.href = `http://pc.uyoungdiy.com?smallui=${getUrlParams('smallui', props.location.search)}&pid=${res.pid}&appver=${res.appver}&mid=${res.mid}&mid2=${res.mid2}`;
    })

    // backPage()
  }

  useEffect(() => {

    if (window && window.external && 'Execute' in window.external) {
      unRegisterFunction();
      registerFunction('on_diy', (res) => {
        const data = JSON.parse(res);
        if (data.wp_type === 'static_wp') {
          window.location.href = `/sku?url=${data.url}&smallui=${getUrlParams('smallui', props.location.search)}`;
        } else {
          window.location.href = `/sku?url=none&smallui=${getUrlParams('smallui', props.location.search)}&path=${data.url}`;
        }
      })
    }
    const small = getUrlParams('smallui', props.location.search);
    setIsBig(+small === 1 ? true : false);
    const name = getUrlParams('name', props.location.search);
    const banner = getUrlParams('banner', props.location.search);
    if (+banner === 1) {
      setIsBanner(1)
    }
    if (name.indexOf('苹果') > -1) {
      newSetClickCount('diy_pc', 'phonecase_result_ios');
      //需要去跳转到公众号查询订单的物流
      showWechartCode(`type=shoujike_pc&value=${getUrlParams('oderid', props.location.search)}&img=${getUrlParams('img', props.location.search)}`).then(res => {
        if (res) {
          setImg(res);
        }
      })
      // setIsAndroid(false);
    } else {
      newSetClickCount('diy_pc', 'phonecase_result_android');
      setIsAndroid(true);
    }
  }, [props.location.search])


  return (
    <div className={isBig ? 'content resultBox small' : 'content resultBox'}>
      <div className="iconBox">
        <img alt="" src="//cdn-ali-file-shfront.shanhutech.cn/front/web/icon_1616032451901.png" />
        <p>恭喜您！购买成功，我们会尽快为您安排发货</p>
      </div>
      {!isAndroid && imgUrl && <Ios img={imgUrl} banner={ isBanner}/>}
      {isAndroid && <Android banner={ isBanner}/>}
      <div className="btnBox" style={{'marginTop': (!isBig && +isBanner === 0) ? '50px' : '14px'}}>
        <button type="button" onClick={() => { backPageFun() }}>完成</button>
      </div>
    </div>
  )
}

export default Result;
